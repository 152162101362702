import React from 'react';
import { connect } from 'react-redux'

import { _setAxios } from '../../../lib/Helper';

import { Row, Col, Empty } from 'antd';


class TataTertib extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            file_active: null
        };
    }

    componentDidMount() {
        this.getFileActive()
    }

    getFileActive = () => {
        const filterTahunAjaran = this.props.privilege.tahun_ajaran ? this.props.privilege.tahun_ajaran.thn_awal + "-" + this.props.privilege.tahun_ajaran.thn_akhir : null
        _setAxios("tatibsi/dokumen/file/" + filterTahunAjaran, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                this.setState({
                    file_active: data && data.file ? data.main_path + data.file : null
                })
            }
        })
    }

    render() {

        return (
            <Row>
                <Col span={24}>
                    {this.state.file_active ?
                        <embed src={this.state.file_active} type="application/pdf" style={{
                            width: "100%",
                            height: 720
                        }} /> : <Empty
                            imageStyle={{
                                height: 60,
                            }}
                            description={
                                <span>
                                    Mohon maaf, belum ada file tata tertib yang aktif.
                                </span>
                            }
                        >
                        </Empty>}
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(TataTertib);
